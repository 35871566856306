import React, { FC, useEffect, useState } from "react";
import { BookItem, ChapterItem, PaginationInfo } from "type";
import { makeGetApi } from "utils/api";
import WidgetBookChapterItem from "./WidgetBookChapterItem";
import { Icon } from "@iconify/react";
import ArchiveFilterListBox from "_components/ArchiveFilterListBox/ArchiveFilterListBox";
import Pagination from "../../_components/Pagination/Pagination";

interface WidgetBookChaptersProps {
  className?: string;
  book?: BookItem;
  defaultSort?: string;
  onChangeSort?: (value: string) => void;
}


const WidgetBookChapters: FC<WidgetBookChaptersProps> = ({
                                                           className = "bg-neutral-100 dark:bg-neutral-800",
                                                           book,
                                                           defaultSort = "desc",
                                                           onChangeSort,
                                                         }) => {

  const FILTERS = [
    { name: "Mới nhất", key: "desc" },
    { name: "Cũ nhất", key: "asc" },
  ];

  const [chapters, setChapters] = useState<ChapterItem[]>([]);
  const [chapterPageInfo, setChapterPageInfo] = useState<PaginationInfo>({
    limit: 0, page: 0, total: 0, totalPage: 0,
  });
  const [sortValue, setSortValue] = useState<string>(defaultSort || FILTERS[0].key);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const getBookChapters = async () => {
    const response = await makeGetApi(`/api/v1/book/${book?.id}/chapters`, {
      limit: 20,
      page: currentPage,
      sortField: "index",
      sortType: sortValue,
    });
    setChapters(response.data?.data?.items);
    setChapterPageInfo(response.data?.data?.extra?.paging);
  };

  useEffect(() => {
    if (book?.id === undefined) {
      return;
    }
    getBookChapters();
  }, [book, currentPage, sortValue]);
  return (
    <div className={`nc-WidgetAuthors overflow-hidden ${className}`}>
      <div
        className={`nc-WidgetHeading1 flex items-center justify-between p-4 xl:p-5 border-b border-neutral-200 dark:border-neutral-700 ${className}`}
      >
        <p className="text-sm text-neutral-900 dark:text-neutral-100 flex-grow">
          {chapterPageInfo.total ? `Hiển thị ${chapterPageInfo.limit} trên tổng ${chapterPageInfo.total} audio` : ""}
        </p>
        <div className="flex justify-end">
          <ArchiveFilterListBox
            defaultValue={defaultSort}
            sizeClass={"pl-2 pr-2 p-1 m-0"}
            optionClass={"py-1 pl-10 pr-1"}
            lists={FILTERS}
            className={"text-sm"}
            onChange={(value) => {
              FILTERS.map((item) => {
                if (!value) {
                  return;
                }
                if (item.name === value.name) {
                  setSortValue(item.key);
                  if (onChangeSort) {
                    onChangeSort(item.key);
                  }
                }
              });
            }} />
        </div>
      </div>

      {/* CHAPTERS */}
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {chapters.map((chapter) => (
          <WidgetBookChapterItem
            key={chapter.id}
            chapter={chapter}
            className="p-2 xl:p-2 hover:bg-neutral-200 dark:hover:bg-neutral-700"
          />
        ))}
      </div>
      {/* PAGINATION */}
      <div
        className="flex flex-col sm:space-y-0 mt-2 mb-2 sm:space-x-3 sm:flex-row sm:justify-center sm:items-center">
        <Pagination
          buttonSizeClass={"text-lg rounded-none pt-1 pb-1 pr-3 pl-3"}
          onClick={(page) => {
            setCurrentPage(page);
          }}
          current={currentPage}
          total={chapterPageInfo.totalPage} />
      </div>
    </div>
  );
};

export default WidgetBookChapters;
