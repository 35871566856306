import React from "react";
import MainNav from "_components/Header/MainNav";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const SiteHeader = () => {
  let pathname = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);
  return <div className="nc-Header2 sticky top-0 w-full z-40">
    <MainNav />
  </div>;
};
export default SiteHeader;
