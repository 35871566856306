import React, { FC, useEffect } from "react";
import { Icon } from "@iconify/react";
import { ChapterItem } from "type";
import convertDurationToHHMMSS from "utils/convertDurationToHHMMSS";
import Button from "_components/Button/Button";
import { useMusicPlayer } from "../../hooks/useMusicPlayer";
import Link from "../../_components/Link";
import NcImage from "../../_components/NcImage/NcImage";

interface WidgetBookChapterItemProps {
  className?: string;
  chapter?: ChapterItem;
}


const WidgetBookChapterItem: FC<WidgetBookChapterItemProps> = ({
                                                                 className = "bg-neutral-100 dark:bg-neutral-100",
                                                                 chapter = {} as ChapterItem,
                                                               }) => {
  const {
    setCurrentChapterId,
    setPlaying,
  } = useMusicPlayer();

  // STATE

  const handleClickPlayButton = (chapterId: any) => {
    setCurrentChapterId(chapterId);
    setPlaying(true);
  };
  return (
    <div className={"position-relative"}>
      <Link
        href={chapter.url}
        className={`nc-CardCategory1 flex items-center ${className}`}
      >
        <NcImage
          alt=""
          containerClassName={`relative flex-shrink-0 w-12 h-12 rounded-lg mr-4 overflow-hidden`}
          src={chapter.poster || ""}
          fill
          className="object-cover"
          sizes="80px"
        />
        <div>
          <h2
            className={`text-base nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
          >
            {chapter.name}
          </h2>
        </div>
      </Link>
      <Button sizeClass={"p-3 ml-2 pl-5"}
              className={"items-center position-absolute rounded-full right-1 top-4"}
              fontSize={"text-sm font-small"} pattern={"third"}
              onClick={() => {
                handleClickPlayButton(chapter.id);
              }}
      >
        <Icon icon={"solar:play-bold"} className={"inline-flex mr-2 text-sm"} />
      </Button>
    </div>
  );
};

export default WidgetBookChapterItem;
