import React, { FC } from "react";
import CategoryBadgeList from "_components/CategoryBadgeList/CategoryBadgeList";
import PostCardReactDisplay from "_components/PostCardReactDisplay/PostCardReactDisplay";
import musicWave from "images/musicWave.png";
import Link from "_components/Link";
import Image from "_components/Image";
import { BookItem } from "../../type";
import convertNullToUndefined from "../../utils/convertNullToUndefined";
import { PostDataType } from "../../data/types";
import PostFeaturedMedia from "_components/PostFeaturedMedia/PostFeaturedMedia";
import PostCardMeta from "../../components/PostCardMeta/PostCardMeta";
import PostCardLikeAndComment from "../../components/PostCardLikeAndComment/PostCardLikeAndComment";
import PostCardSaveAction from "../../components/PostCardSaveAction/PostCardSaveAction";

export interface CardAudioItemProps {
  className?: string;
  book: BookItem;
  ratio?: string;
  hiddenAuthor?: boolean;
}


const CardAudioItem: FC<CardAudioItemProps> = ({
                                                 className = "h-full",
                                                 book,
                                                 hiddenAuthor = false,
                                                 ratio = "aspect-w-3 aspect-h-3",
                                               }) => {
  const { name, url, description, poster, sortDate, views, likes, category } = book;

  return (
    <div
      className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 ${className}`}
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
      >
        <div>
          <PostFeaturedMedia book={book} />
        </div>
      </div>
      <Link href={url} className="absolute inset-0"></Link>
      <span className="absolute top-3 inset-x-3 z-10">
        <CategoryBadgeList categories={category} />
      </span>

      <div className="p-4 flex flex-col space-y-3">
        <span className="text-xs text-neutral-500">{sortDate}</span>
        <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <span className="line-clamp-2" title={name}>
            {name}
          </span>
        </h3>
        <div className="flex items-end justify-between mt-auto">
          <PostCardReactDisplay className="relative" likeCount={convertNullToUndefined(likes) || 0}
                                viewCount={convertNullToUndefined(views) || 0} />
        </div>
      </div>
    </div>

  );
};

export default CardAudioItem;
