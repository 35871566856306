import React, { FC } from "react";
import Avatar from "_components/Avatar/Avatar";
import Link from "_components/Link";
import { BookItem } from "type";
import { Route } from "routers/types";

export interface PostMetaProps {
  className?: string;
  book?: BookItem;
  hiddenCategories?: boolean;
  size?: "large" | "normal";
  avatarRounded?: string;
}

const PostMeta: FC<PostMetaProps> = ({
                                       className = "leading-none",
                                       book ={} as BookItem,
                                       hiddenCategories = false,
                                       size = "normal",
                                       avatarRounded,
                                     }) => {
  const author = book?.speaker?.[0];
  const category = book?.category;
  return (
    <div
      className={`nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 ${
        size === "normal" ? "text-xs" : "text-sm"
      } ${className}`}
    >
      <Link href={author?.url as Route} className="flex items-center space-x-2">
        <Avatar
          radius={avatarRounded}
          sizeClass={
            size === "normal"
              ? "h-6 w-6 text-sm"
              : "h-10 w-10 sm:h-11 sm:w-11 text-xl"
          }
          imgUrl={author?.avatar}
          userName={author?.name}
        />
      </Link>
      <div className="ml-3">
        <div className="flex items-center">
          <Link href={author?.url as Route} className="block font-semibold">
            {author?.name}
          </Link>

          {!hiddenCategories && (
            <>
              <span className="mx-2 font-semibold">·</span>
              <div className="ml-0">
                <span className="text-xs">🏷 </span>
                {category?.map((cat, index) => (
                  <Link key={cat.id} href={cat.url as Route} className="font-semibold">
                    {cat.name}
                    {index < category?.length - 1 && <span>, </span>}
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="text-xs mt-[6px]">
          <span className="text-neutral-700 dark:text-neutral-300">{book?.sortDate}</span>
          {/*<span className="mx-2 font-semibold">·</span>*/}
          {/*<span className="text-neutral-700 dark:text-neutral-300">*/}
          {/*  {readingTime} min read*/}
          {/*</span>*/}
        </div>
      </div>
    </div>
  );
};

export default PostMeta;
