import React, {
  FC,
  LegacyRef,
  useRef,
  useState,
  ChangeEvent,
  useEffect,
} from "react";
import PlayerContent from "./PlayerContent";
import _ from "lodash";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { useMusicPlayer } from "hooks/useMusicPlayer";
import { makeGetApi } from "../../utils/api";
import { BookItem, ChapterItem } from "type";

export interface MusicPlayerProps {
}

const MusicPlayer: FC<MusicPlayerProps> = () => {
  const playerRef: LegacyRef<ReactPlayer> | undefined = useRef(null);
  const {
    muted,
    playbackRate,
    playing,
    setDuration,
    setLoaded,
    setMuted,
    setPlayed,
    setPlaying,
    setVolume,
    setPlaybackRate,
    volume,
    playedSeconds,
    currentBookId,
    setCurrentBookId,
    currentChapterId,
    setCurrentChapterId,
    setBookData,
    chapterData,
    setChapterData,
    setPlayedSeconds,
    setLoadedSeconds,
    audioUrl,
    setAudioUrl,
    backupAudioUrls,
    setBackupAudioUrls,
    seekTo,
    setSeekTo,
    setQuickBookId,
    quickBookId,
  } = useMusicPlayer();
  const [isReady, setIsReady] = React.useState(false);

  const getBookChapters = async (bookId: string, limit: number = 200, page: number = 1, sortValue: string) => {
    const response = await makeGetApi(`/api/v1/book/${bookId}/chapters`, {
      limit: limit,
      page: page,
      sortField: "index",
      sortType: sortValue,
    });
    try {
      let currentChapterData: any[] | ((prevState: ChapterItem[]) => ChapterItem[]) = [];
      currentChapterData = currentChapterData.concat(response.data.data?.items || []);
      setChapterData(currentChapterData);
      return currentChapterData;
    } catch (error) {
    }
    return [];
  };
  const getChapterDetail = async (chapterId: string) => {
    const response = await makeGetApi(`/api/v1/book/chapter/${chapterId}`, {
      withLinkPlay: true,
    });
    try {
      setIsReady(false);
      setAudioUrl(response.data.data.link);
      setBackupAudioUrls(response.data.data.backupLinks || []);
      let bookData = {
        id: response.data.data.bookId,
        name: response.data.data.name,
        cover: response.data.data.cover,
        poster: response.data.data.poster,
        content: response.data.data.content,
        url: response.data.data.url,

      };
      setBookData(bookData as BookItem);
      // setPlaying(true);
      setCurrentBookId(response.data.data.bookId);
    } catch (error) {
      setAudioUrl(undefined);
      setBackupAudioUrls([]);
      setPlaying(false);
    }
  };

  const getQuickBookChapters = async () => {
    if (quickBookId === undefined) {
      return;
    }
    let chapters = await getBookChapters(quickBookId, 200, 1, "asc");
    console.log("chapters", chapters);
    if (chapters.length === 0) {
      return;
    }
    setCurrentChapterId(chapters[0].id);
  };
  useEffect(() => {
    if (currentChapterId === undefined) {
      setPlaying(false);
      setBookData(undefined);
      setChapterData([]);
      setCurrentBookId(undefined);
      setAudioUrl(undefined);
      setBackupAudioUrls([]);
      setIsReady(false);
      setQuickBookId(undefined);
      return;
    }
    getChapterDetail(currentChapterId);
  }, [currentChapterId]);

  useEffect(() => {
    if (currentChapterId === undefined) {
      return;
    }
    getBookChapters(currentBookId || "", 200, 1, "asc");
  }, [currentBookId]);

  useEffect(() => {
    if (quickBookId === undefined) {
      return;
    }
    getQuickBookChapters();
  }, [quickBookId]);

  // STATE
  const [seeking, setSeeking] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRender, setIsRender] = useState(false);

  //
  useEffect(() => {
    setIsRender(true);
  }, []);
  //

  const handleSeekMouseUp = (
    e:
      | React.MouseEvent<HTMLInputElement, MouseEvent>
      | React.TouchEvent<HTMLInputElement>,
  ) => {
    setSeeking(false);
    playerRef?.current?.seekTo(parseFloat(e.currentTarget.value));
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleVolumeChange = (e: number) => {
    setVolume(e);
  };

  const handleSetPlaybackRate = (e: number) => {
    setPlaybackRate(e);
  };

  const onClickBackwards10Sec = () => {
    playerRef?.current?.seekTo(playedSeconds - 10, "seconds");
  };

  const onClickForwards15Sec = () => {
    playerRef?.current?.seekTo(playedSeconds + 15, "seconds");
  };

  const handlePlay = () => {
    setPlaying(true);
  };
  const handlePause = () => {
    setPlaying(false);
  };

  const handleEnded = () => {
    let currentChapterIndex = chapterData?.findIndex((chapter) => chapter.id === currentChapterId);
    const isCanNextChapter = currentChapterIndex !== chapterData?.length - 1;
    if (isCanNextChapter) {
      setCurrentChapterId(chapterData?.[currentChapterIndex as number + 1].id);
      setPlaying(true);
      return;
    }
    setPlaying(false);
  };

  const handleProgress: ReactPlayerProps["onProgress"] = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setLoaded(state.loaded);
      setPlayed(state.played);
      setPlayedSeconds(state.playedSeconds);
      setLoadedSeconds(state.loadedSeconds);
    }
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };
  useEffect(() => {
    console.log("seekTo", seekTo, isReady);
    if (isReady) {
      if (seekTo > 0) {
        playerRef?.current?.seekTo(seekTo, "seconds");
      }
      setIsReady(false);
      setSeekTo(0);
    }
  }, [isReady]);
  const handleOnReady = () => {
    setIsReady(true);
  };

  return (
    <div className={`nc-MusicPlayer fixed bottom-0 inset-x-0 flex z-30`}>
      {/* ---- PLAYER CONTROL ---- */}
      <PlayerContent
        isError={isError}
        handleSetMuted={(isMuted) => setMuted(isMuted)}
        handleSeekMouseUp={handleSeekMouseUp}
        handleSeekMouseDown={handleSeekMouseDown}
        handleSeekChange={handleSeekChange}
        handleVolumeChange={handleVolumeChange}
        handleSetPlaybackRate={handleSetPlaybackRate}
        handleClickBackwards10Sec={_.debounce(onClickBackwards10Sec, 200)}
        handleClickForwards15Sec={_.debounce(onClickForwards15Sec, 200)}
      />

      {/* ---- PLAYER ---- */}
      <div className="fixed top-0 left-0 w-1 h-1 -z-50 opacity-0 overflow-hidden invisible">
        {isRender ? (
          <ReactPlayer
            ref={playerRef}
            className="react-player"
            width="100%"
            height="100%"
            url={audioUrl || ""}
            // forceAudio={true}
            playing={playing}
            controls
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onReady={() => handleOnReady()}
            onStart={() => setIsError(false)}
            onPlay={handlePlay}
            onPause={handlePause}
            // onBuffer={() => console.log("onBuffer")}
            // onSeek={(e) => console.log("onSeek", e)}
            onEnded={handleEnded}
            onError={(e) => {
              if (backupAudioUrls.length > 0) {
                setAudioUrl(backupAudioUrls[0]);
                setBackupAudioUrls(backupAudioUrls.slice(1));
              } else {
                setIsError(true);
              }
            }
            }
            onProgress={handleProgress}
            onDuration={handleDuration}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MusicPlayer;
