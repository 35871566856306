import React, { FC } from "react";
import { Icon } from "@iconify/react";
import { BookItem, TagInfo } from "type";
import Link from "../../components/Link";
import WidgetHeading from "../../_components/WidgetHeading/WidgetHeading";

interface WidgetDetailInfoProps {
  className?: string;
  book?: BookItem;
}


const WidgetDetailInfo: FC<WidgetDetailInfoProps> = ({
                                                       className = "bg-neutral-100 dark:bg-neutral-800",
                                                       book = {} as BookItem,
                                                     }) => {
  const category = book.category;
  const speaker = book.speaker;
  const publisher = book.publisher;
  const country = book.country;
  const renderTagInfo = (name: string, icon: string, tagInfo: TagInfo[]) => {
    if (!tagInfo) return null;
    return <div className="flex items-center space-x-2 mb-3">
            <span className="text-neutral-600 dark:text-neutral-400 text-sm">
              <Icon icon={icon} className={"inline-flex"} /> {name}
            </span>
      <span className="text-neutral-700 dark:text-neutral-300 text-sm">
              {tagInfo?.map((item: TagInfo, index: number) => (
                <><Link key={index} href={item.url}>{item.name}</Link>, </>
              ))}
            </span>
    </div>;
  };

  const renderText = (name: string, icon: string, text: string) => {
    return <div className="flex items-center space-x-2 mb-3">
            <span className="text-neutral-600 dark:text-neutral-400 text-sm">
              <Icon icon={icon} className={"inline-flex"} /> {name}
            </span>
      <span className="text-neutral-700 dark:text-neutral-300 text-sm">
              {text}
            </span>
    </div>;
  };
  return (
    <div className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}>
      <WidgetHeading
        title="✨ Thông tin"
      />
      <div className="flex flex-wrap p-4 xl:p-5">
        <div className="w-full">
          {renderText("Số chương: ", "carbon:book", book.chapters?.toString() || "N/A")}
          {renderText("Đã hoàn thành: ", "carbon:book", book.processChapters?.toString() || "N/A")}
          {renderText("Trạng thái: ", "fluent-mdl2:sync-status", book.processStatus!)}
          {renderTagInfo("Tác giả: ", "carbon:user-avatar-filled", book.author!)}
          {renderTagInfo("Thể loại: ", "carbon:category", category!)}
          {renderTagInfo("Giọng đọc: ", "icon-park-solid:voice", speaker!)}
          {renderTagInfo("Nhà xuất bản: ", "fluent-mdl2:publish-course", publisher!)}
          {renderTagInfo("Quốc gia: ", "ion:earth-sharp", country!)}
        </div>
      </div>
    </div>
  );
};

export default WidgetDetailInfo;
