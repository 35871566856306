import React, { FC } from "react";
import CategoryBadgeList from "_components/CategoryBadgeList/CategoryBadgeList";
import PostCardReactDisplay from "_components/PostCardReactDisplay/PostCardReactDisplay";
import musicWave from "images/musicWave.png";
import Link from "_components/Link";
import Image from "_components/Image";
import { BookItem } from "../../type";
import convertNullToUndefined from "../../utils/convertNullToUndefined";
import { Icon } from "@iconify/react";
import { useMusicPlayer } from "../../hooks/useMusicPlayer";

// import ReactHtmlParser from "react-html-parser";

export interface CardPodCastItemProps {
  className?: string;
  book: BookItem;
  ratio?: string;
}

const CardPodCastItem: FC<CardPodCastItemProps> = ({
                                                     className = "h-full",
                                                     book,
                                                     ratio = "aspect-w-3 xl:aspect-w-3 aspect-h-3",
                                                   }) => {
  const {
    setQuickBookId,
    setPlaying,
  } = useMusicPlayer();

  const { name, url, description, poster, views, likes, category } = book;

  const handlePlayClick = () => {
    setQuickBookId(book.id);
    setPlaying(true);
  };
  return (
    <div className={`nc-Card16Podcast relative flex flex-col ${className}`}>
      <Link
        href={url}
        className={`block flex-shrink-0 relative w-full rounded-3xl overflow-hidden ${ratio}`}
      >
        <Image
          fill
          alt=""
          sizes="(max-width: 600px) 480px, 800px"
          src={poster}
          className="object-cover"
        />
        <span className="bg-neutral-900 bg-opacity-30"></span>
      </Link>
      <div className="m-button-listen">
        <div
          title={"Nghe nhanh"}
          onClick={handlePlayClick}
          className="text-white bg-black bg-blend-multiply bg-opacity-50 w-20 h-20 border-2 border-neutral-300 rounded-full flex items-center justify-center ">
          <Icon icon={"bi:play-fill"} className="ml-0.5 w-7 h-7" />
        </div>
      </div>
      {/* ABSOLUTE */}
      <Link href={url} className="absolute inset-0"></Link>
      <span className="absolute top-3 inset-x-3">
        <CategoryBadgeList categories={category} />
      </span>

      {/* MAIN CONTENT */}
      <div className="w-11/12 transform -mt-32 ">
        <div
          className={`px-5 flex items-center space-x-4 relative opacity-0 z-[-1]`}
        >
          <div className={`flex-grow `}>
            <Image src={musicWave} alt="musicWave" />
          </div>
        </div>
        <div
          className="p-5 mt-5 bg-white dark:bg-neutral-900 shadow-xl dark:shadow-2xl rounded-3xl rounded-tl-none flex flex-col flex-grow ">
          <h4
            className="nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 ">
            <Link href={url} className="line-clamp-1" title={name}>
              {name}
            </Link>
          </h4>
          <span className="block text-sm text-neutral-500 dark:text-neutral-400 mt-3 mb-5">
            <div className="line-clamp-2" dangerouslySetInnerHTML={{ __html: description }}></div>
          </span>
          <div className="flex items-end justify-between mt-auto">
            <PostCardReactDisplay className="relative" likeCount={convertNullToUndefined(likes) || 0}
                                  viewCount={convertNullToUndefined(views) || 0} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPodCastItem;
