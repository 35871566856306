import React, { FC, useEffect } from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "app/headlessui";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import Button from "../Button/Button";


export interface ArchiveFilterListBoxProps {
  className?: string;
  sizeClass?: string;
  optionClass?: string;
  onChange?: (value: any) => void;
  lists: { name: string, key: string }[];
  defaultValue?: string;
}

const ArchiveFilterListBox: FC<ArchiveFilterListBoxProps> = ({
                                                               className = "",
                                                               sizeClass = "",
                                                               optionClass = "",
                                                               lists,
                                                               onChange,
                                                               defaultValue,
                                                             }) => {
  let [selected, setSelected] = useState(null);
  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
  }, [selected]);
  let defaultSelected: any = {};
  if (defaultValue) {
    defaultSelected = lists.find((item) => item.key === defaultValue);
  }
  let selectedValue: any = selected || defaultSelected;
  return (
    <div className={`nc-ArchiveFilterListBox flex-shrink-0 ${className}`}>
      <Listbox value={selected || defaultSelected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button as={"div"}>
            <Button pattern="third" fontSize="text-sm font-medium" sizeClass={sizeClass} className={"rounded-none"}>
              {selectedValue?.name}
              <ChevronDownIcon
                className="w-4 h-4 ml-2 -mr-1"
                aria-hidden="true"
              />
            </Button>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className={`absolute right-0 w-52 py-1 mt-2 overflow-auto text-sm
              text-neutral-900 dark:text-neutral-200 bg-white rounded-xl shadow-lg max-h-60 ring-1 ring-black ring-opacity-5
               focus:outline-none dark:bg-neutral-900 dark:ring-neutral-700 z-50`
            }>
              {lists.map((item, index: number) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `${
                      active
                        ? "text-primary-700 dark:text-neutral-200 bg-primary-50 dark:bg-neutral-700"
                        : ""
                    } cursor-default select-none relative ${optionClass ? optionClass : "py-2 pl-10 pr-4"}`
                  }
                  value={item}
                >
                  <span
                    className={`${
                      selectedValue ? "font-medium" : "font-normal"
                    } block truncate`}
                  >
                    {item.name}
                  </span>
                  {item.name === selectedValue?.name ? (
                    <span
                      className="text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-neutral-200">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                  ) : null}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default ArchiveFilterListBox;
