import { Dispatch } from "react";
import { makeGetApi, makePostApi } from "../../utils/api";
import { ActionType, Action } from "./actiontTypes";
import { BookItem, HomeBannerItem } from "../../type";

interface HomepageState {
  error: string | null;
  hot_banners: Array<HomeBannerItem>;
  newest_audio_book: Array<BookItem>;
  newest_audio_story: Array<BookItem>;
  newest_audio_podcast: Array<BookItem>;
  newest_audio_radio: Array<BookItem>;
  newest_audio_ngon_tinh: Array<BookItem>;
  newest_audio_truyen_ma: Array<BookItem>;
  newest_audio_live: Array<BookItem>;
}

const initialState = {
  error: null,
  hot_banners: [],
  newest_audio_book: [],
  newest_audio_story: [],
  newest_audio_podcast: [],
  newest_audio_radio: [],
  newest_audio_ngon_tinh: [],
  newest_audio_truyen_ma: [],
  newest_audio_live: [],
};

const homeReducer = (state: HomepageState = initialState, action: Action): HomepageState => {
  switch (action.type) {
    case ActionType.GET_HOME_HOT_BANNER:
      return {
        ...state,
        hot_banners: action.payload,
      };
    case ActionType.GET_NEWEST_AUDIO_BOOK:
      return {
        ...state,
        newest_audio_book: action.payload,
      };
    case ActionType.GET_NEWEST_AUDIO_STORY:
      return {
        ...state,
        newest_audio_story: action.payload,
      };
    case ActionType.GET_NEWEST_AUDIO_PODCAST:
      return {
        ...state,
        newest_audio_podcast: action.payload,
      };
    case ActionType.GET_NEWEST_AUDIO_RADIO:
      return {
        ...state,
        newest_audio_radio: action.payload,
      };
    case ActionType.GET_NEWEST_AUDIO_NGON_TINH:
      return {
        ...state,
        newest_audio_ngon_tinh: action.payload,
      };
    case ActionType.GET_NEWEST_AUDIO_TRUYEN_MA:
      return {
        ...state,
        newest_audio_truyen_ma: action.payload,
      };
    case ActionType.GET_NEWEST_AUDIO_LIVE:
      return {
        ...state,
        newest_audio_live: action.payload,
      };
    default:
      return state;
  }
};

export default homeReducer;

export function getHomePageBanner() {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makeGetApi("/api/v1/promotion/banners", {
      position: "homepage_hot_banner",
    });
    try {
      dispatch({
        type: ActionType.GET_HOME_HOT_BANNER,
        payload: response.data.data.items,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_HOME_HOT_BANNER,
        payload: [],
      });
    }
  };
}

export function getHomePageNewestAudioStory() {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makePostApi("/api/v1/books/filter", {
      limit: 12,
      page: 1,
      audioType: "story",
      sortField: "updateDate",
      sortType: "desc",
    });
    try {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_STORY,
        payload: response.data.data.items,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_STORY,
        payload: [],
      });
    }
  };
}

export function getHomePageNewestAudioBook() {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makePostApi("/api/v1/books/filter", {
      limit: 12,
      page: 1,
      audioTypes: ["audiobook", "podcast", "story"],
      sortField: "updateDate",
      sortType: "desc",
    });
    try {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_BOOK,
        payload: response.data.data.items,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_BOOK,
        payload: [],
      });
    }
  };
}

export function getHomePageNewestAudioPodcast() {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makePostApi("/api/v1/books/filter", {
      limit: 4,
      page: 1,
      audioType: "podcast",
      sortField: "updateDate",
      sortType: "desc",
    });
    try {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_PODCAST,
        payload: response.data.data.items,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_PODCAST,
        payload: [],
      });
    }
  };
}

export function getHomePageNewestRadio() {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makePostApi("/api/v1/books/filter", {
      limit: 12,
      page: 1,
      audioType: "radio",
      sortField: "updateDate",
      sortType: "desc",
    });
    try {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_RADIO,
        payload: response.data.data.items,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_RADIO,
        payload: [],
      });
    }
  };
}

export function getHomePageNewestAudioNgonTinh() {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makePostApi("/api/v1/books/filter", {
      limit: 12,
      page: 1,
      tagIds: ["7299e0c0fb7244f9b96177e8a3415627"],
      sortField: "updateDate",
      sortType: "desc",
    });
    try {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_NGON_TINH,
        payload: response.data.data.items,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_NGON_TINH,
        payload: [],
      });
    }
  };
}

export function getHomePageNewestAudioTruyenMa() {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makePostApi("/api/v1/books/filter", {
      limit: 12,
      page: 1,
      tagIds: ["fe843e43af5a4fb5854aa060432cecf8"],
      sortField: "updateDate",
      sortType: "desc",
    });
    try {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_TRUYEN_MA,
        payload: response.data.data.items,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_TRUYEN_MA,
        payload: [],
      });
    }
  };
}

export function getHomePageNewestAudioLive() {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makePostApi("/api/v1/books/filter", {
      limit: 12,
      page: 1,
      tagIds: ["40b42c33c95949cb894f6d2153cd0f77"],
      sortField: "updateDate",
      sortType: "desc",
    });
    try {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_LIVE,
        payload: response.data.data.items,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_NEWEST_AUDIO_LIVE,
        payload: [],
      });
    }
  };
}

