import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import LoadingScreen from "./LoadingScreen";
import { isValidToken } from "../utils/jwt";
import { postLoginAnonymous, getProfile } from "../redux/slices/user";
import { getMenus } from "../redux/slices/menu";

import { useDispatch } from "../redux/store";
// ----------------------------------------------------------------------

type Props = {
  children: string | JSX.Element | JSX.Element[]
}

export default function PreAuthGuard({ children }: Props) {
  const dispatch = useDispatch();
  const { accessToken, isLoading, user } = useSelector((state: any) => state.user);
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    dispatch(getMenus());
  }, []);

  const initialize = async (force?: boolean) => {
    if ((!force && isValidToken(accessToken)) || isLoading) {
      return;
    }
    const saveAccessToken = window.localStorage.getItem("vradio-accessToken");
    if (saveAccessToken && isValidToken(saveAccessToken)) {
      if (await dispatch(getProfile(saveAccessToken))) {
        dispatch(getMenus());
        return;
      }
    }
    window.localStorage.removeItem("vradio-accessToken");
    dispatch(postLoginAnonymous());
  };

  if (!isValidToken(accessToken)) {
    initialize();
    return <LoadingScreen />;
  } else {
    if (user?.time && new Date().getTime() - user?.time > 1000 * 60 * 60) {
      initialize(true);
      return <LoadingScreen />;
    }
  }
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  return <>{children}</>;
}
