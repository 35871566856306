import React, { FC, useEffect, useState } from "react";
import { BookItem } from "type";
import WidgetHeading from "_components/WidgetHeading/WidgetHeading";
import WidgetCardBookRelated from "_components/WidgetCardBookRelated/WidgetCardBookRelated";
import { makePostApi } from "../../utils/api";

interface WidgetBookOtherVoiceProps {
  className?: string;
  book?: BookItem;
}


const WidgetBookOtherVoice: FC<WidgetBookOtherVoiceProps> = ({
                                                               className = "bg-neutral-100 dark:bg-neutral-800",
                                                               book = {} as BookItem,
                                                             }) => {
  const [bookOtherVoice, setBookOtherVoice] = useState([] as BookItem[]);
  const getBookOtherVoice = async () => {
    if (book?.id === undefined) {
      return;
    }
    if (book?.speaker === undefined || book?.speaker?.length === 0) {
      return;
    }
    const response = await makePostApi(`/api/v1/books/${book?.id}/related/other_voice`, {
      keyword: book?.name,
      limit: 5,
    });
    setBookOtherVoice(response.data?.data?.items);
  };

  useEffect(() => {
    if (book?.id === undefined) {
      return;
    }
    getBookOtherVoice();
  }, [book]);

  return (
    <div
      className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
    >
      {bookOtherVoice.length === 0 ? null : (
        <>
          <WidgetHeading
            title="✨ Giọng đọc khác"
          />
          <div className="flow-root">
            <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
              {bookOtherVoice?.map((child_book: BookItem) => (
                <WidgetCardBookRelated
                  className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                  key={child_book?.id}
                  book={child_book}
                  displayVoice={true}
                  size="normal"
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WidgetBookOtherVoice;
