import NcImage from "_components/NcImage/NcImage";
import NextPrev from "_components/NextPrev/NextPrev";
import { HomeBannerItem } from "../../type";
import React, { FC } from "react";
import Link from "_components/Link";
import Badge from "_components/Badge/Badge";
import PostCardReactDisplay from "../PostCardReactDisplay/PostCardReactDisplay";

export interface HotBannerProps {
  className?: string;
  post: HomeBannerItem;
  onClickNext?: () => void;
  onClickPrev?: () => void;
}

const HotBanner: FC<HotBannerProps> = ({
                                         className = "",
                                         post,
                                         onClickNext = () => {
                                         },
                                         onClickPrev = () => {
                                         },
                                       }) => {
  const {
    clickLink, customTitle,
    clickTarget, image, description,
    extraLink, likeCount, viewCount, commentCount,
  } = post;

  return (
    <div
      className={`nc-CardLarge1 nc-CardLarge1--hasAnimation relative flex flex-col-reverse md:flex-row justify-end ${className}`}
    >
      <div
        className="md:absolute z-10 md:left-0 md:top-1/2 md:-translate-y-1/2 w-full -mt-8 md:mt-0 px-3 sm:px-6 md:px-0 md:w-3/5 lg:w-1/2 xl:w-2/5">
        <div
          className="nc-CardLarge1__left p-4 sm:p-8 xl:py-14 md:px-10 bg-white/40 dark:bg-neutral-900/40 backdrop-filter backdrop-blur-lg shadow-lg dark:shadow-2xl rounded-3xl space-y-3 sm:space-y-5 ">

          <div
            className={`nc-CategoryBadgeList ${className}`}
            data-nc-id="CategoryBadgeList"
          >
            {extraLink?.map((item, index) => (
              <Badge
                className={"flex flex-wrap space-x-2"}
                key={index}
                name={item.name}
                href={item.url}
                // color={item.color as any}
              />
            ))}
          </div>
          <h2 className="nc-card-title text-base sm:text-xl lg:text-2xl font-semibold ">
            <Link href={clickLink} className="line-clamp-2" title={customTitle}>
              {customTitle}
            </Link>
          </h2>
          <h3>
            {description}
          </h3>
          <div className="flex items-center justify-between mt-auto">
            <PostCardReactDisplay likeCount={likeCount} viewCount={viewCount} commentCount={commentCount} />
          </div>
        </div>
        <div className="p-4 sm:pt-8 sm:px-10">
          <NextPrev
            btnClassName="w-11 h-11 text-xl"
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        </div>
      </div>
      <div className="w-full md:w-4/5 lg:w-2/3">
        <Link href={clickLink} className="nc-CardLarge1__right block relative">
          <NcImage
            containerClassName="aspect-w-16 aspect-h-12 sm:aspect-h-9 md:aspect-h-14 lg:aspect-h-10 2xl:aspect-h-9 relative"
            className="absolute inset-0 object-cover rounded-3xl"
            src={image}
            alt={customTitle}
            fill
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </Link>
      </div>
    </div>
  );
};

export default HotBanner;
