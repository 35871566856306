import { Dispatch } from "react";
import { makeGetApi, makePostApi } from "../../utils/api";
import { ActionType, Action } from "./actiontTypes";

// ----------------------------------------------------------------------
interface UserState {
  user: any | null;
  loading: boolean;
  error: string | null;
  time: number | null
  accessToken: string | null,
}

const initialState = {
  loading: false,
  error: null,
  user: null,
  time: null,
  accessToken: null,
};

const userReducer = (state: UserState = initialState, action: Action): UserState => {
  switch (action.type) {
    case ActionType.USER_GET_ANONYMOUS_TOKEN:
      return {
        loading: false,
        accessToken: action.payload,
        error: null,
        user: null,
        time: new Date().getTime(),
      };
    case ActionType.USER_GET_ANONYMOUS_TOKEN_FAIL:
      return {
        loading: false,
        error: action.payload,
        user: null,
        time: null,
        accessToken: null,
      };
    case ActionType.USER_GET_PROFILE:
      return {
        loading: false,
        accessToken: action.payload.accessToken,
        error: null,
        user: action.payload,
        time: new Date().getTime(),
      };
    default:
      return state;
  }
};

export default userReducer;

export const postLoginAnonymous = () => {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makePostApi(`/api/v1/users/login/anonymous`, {});
    try {
      const respData = response.data?.data?.accessToken;
      window.localStorage.setItem("vradio-accessToken", respData?.token);
      dispatch({
        type: ActionType.USER_GET_ANONYMOUS_TOKEN,
        payload: respData?.token,
      });

    } catch (err) {
      dispatch({
        type: ActionType.USER_GET_ANONYMOUS_TOKEN_FAIL,
        payload: response.data?.message,
      });
    }
  };
};

export function getProfile(accessToken: string) {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makeGetApi("/api/v1/user/profile", {}, {
      headers: { Authorization: `${accessToken}` },
    });
    try {
      let user = response.data.data;
      user.accessToken = accessToken;
      dispatch({
        type: ActionType.USER_GET_PROFILE,
        payload: user,
      });
      return user;
    } catch (error) {
      window.localStorage.removeItem("vradio-accessToken");
      dispatch({
        type: ActionType.USER_GET_PROFILE_FAIL,
        payload: response.data?.message,
      });
    }
  };
}