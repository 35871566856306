import HotBanner from "_components/HotBanner/HotBanner";
import { HomeBannerItem } from "../../type";
import React, { FC, useState } from "react";

export interface SectionHotBannerSliderProps {
  className?: string;
  posts: HomeBannerItem[];
}

const SectionHotBannerSlider: FC<SectionHotBannerSliderProps> = ({
                                                                   posts,
                                                                   className = "",
                                                                 }) => {
  const [indexActive, setIndexActive] = useState(0);

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= posts.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return posts.length - 1;
      }
      return state - 1;
    });
  };

  return (
    <div className={`nc-SectionLargeSlider relative ${className}`}>
      {posts.map((item, index) => {
        if (indexActive !== index) return null;
        return (
          <HotBanner
            key={index}
            onClickNext={handleClickNext}
            onClickPrev={handleClickPrev}
            post={item}
          />
        );
      })}
    </div>
  );
};

export default SectionHotBannerSlider;
