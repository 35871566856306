import React, { FC } from "react";
import PostCardLikeDisplay from "./PostCardLikeDisplay";
import PostCardViewDisplay from "./PostCardViewDisplay";
import PostCardCommentDisplay from "./PostCardCommentDisplay";

export interface PostCardLikeAndCommentProps {
  className?: string;
  itemClass?: string;
  hiddenCommentOnMobile?: boolean;
  useOnSinglePage?: boolean;
  likeCount?: number | undefined;
  viewCount?: number | undefined;
  commentCount?: number;
}

const PostCardReactDisplay: FC<PostCardLikeAndCommentProps> = ({
                                                                 className = "",
                                                                 itemClass = "px-3 h-8 text-xs",
                                                                 hiddenCommentOnMobile = true,
                                                                 useOnSinglePage = false,
                                                                 likeCount,
                                                                 viewCount,
                                                                 commentCount,
                                                               }) => {
  return (
    <div
      className={`nc-PostCardLikeAndComment flex items-center space-x-2 ${className}`}
    >
      {/*{likeCount !== undefined && (*/}
      {/*  <PostCardLikeDisplay className={itemClass} count={likeCount} />*/}
      {/*)}*/}
      {viewCount !== undefined && (
        <PostCardViewDisplay className={itemClass} count={viewCount} />
      )}
      {/*{commentCount !== undefined && (*/}
      {/*  <PostCardCommentDisplay*/}
      {/*    count={commentCount}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default PostCardReactDisplay;
