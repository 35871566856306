import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "redux/store";
import { getBookDetail } from "redux/slices/book";
import { APP_TITLE } from "../../config";
import PageBookDetailSingle from "./page_single";
import PageBookDetailList from "./page_list";

const PageBookDetail = () => {
  const dispatch = useDispatch();
  const { bookSlug = "" } = useParams();
  const {
    book_detail,
  } = useSelector((state: any) => state.book);

  useEffect(() => {
    if (book_detail?.id === undefined || book_detail?.id !== bookSlug) {
      dispatch(getBookDetail(bookSlug));
    }
  }, [bookSlug]);

  useEffect(() => {
    document.title = book_detail?.name + APP_TITLE;
  }, [book_detail]);

  if (book_detail?.isSingle) {
    return <PageBookDetailSingle />;
  }
  return <PageBookDetailList />;
};

export default PageBookDetail;
