import React, { FC } from "react";
import NcImage from "_components/NcImage/NcImage";
import Link from "_components/Link";
import { BookItem } from "../../type";

export interface CardCategory1Props {
  className?: string;
  book: BookItem;
  size?: "large" | "normal";
  displayVoice?: boolean;
}

const WidgetCardBookRelated: FC<CardCategory1Props> = ({
                                                         className = "",
                                                         size = "normal",
                                                         book,
                                                         displayVoice = false,
                                                       }) => {
  const { name, url = "/", poster, views } = book;
  return (
    <Link
      href={url}
      className={`nc-CardCategory1 flex items-center ${className}`}
    >
      <NcImage
        alt=""
        containerClassName={`relative flex-shrink-0 ${
          size === "large" ? "w-20 h-20" : "w-12 h-12"
        } rounded-lg mr-4 overflow-hidden`}
        src={poster || ""}
        fill
        className="object-cover"
        sizes="80px"
      />
      <div>
        <h2
          className={`${
            size === "large" ? "text-lg" : "text-base"
          } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
        >
          {name}
        </h2>
        {displayVoice && (
          <span className="text-neutral-500 dark:text-neutral-400 text-xs block mt-1">
            Giọng đọc: {book.speaker?.map((item) => item.name).join(", ")}
          </span>
        )}
        {!displayVoice && (
          <span
            className={`${
              size === "large" ? "text-sm" : "text-xs"
            } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
          >
          {views} views
        </span>
        )}
      </div>
    </Link>
  );
};

export default WidgetCardBookRelated;
