import { Dispatch } from "react";
import { makeGetApi } from "utils/api";
import { ActionType, Action } from "./actiontTypes";
import { BookItem, ChapterItem } from "type";

interface BookState {
  error: string | null;
  book_detail: BookItem | null;
  chapter_detail: ChapterItem | null;
}

const initialState = {
  error: null,
  book_detail: null,
  chapter_detail: null,
};

const bookReducer = (state: BookState = initialState, action: Action): BookState => {
  switch (action.type) {
    case ActionType.GET_BOOK_DETAIL:
      return {
        ...state,
        book_detail: action.payload,
      };
    case ActionType.GET_BOOK_DETAIL_FAIL:
      return {
        ...state,
        book_detail: null,
        error: action.payload,
      };
    case ActionType.GET_BOOK_CHAPTER_DETAIL:
      return {
        ...state,
        chapter_detail: action.payload,
      };
    case ActionType.GET_BOOK_CHAPTER_DETAIL_FAIL:
      return {
        ...state,
        chapter_detail: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default bookReducer;

export function getBookDetail(book_id: string) {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makeGetApi(`/api/v1/book/${book_id}`);
    try {
      dispatch({
        type: ActionType.GET_BOOK_DETAIL,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_BOOK_DETAIL_FAIL,
        payload: response.data.message,
      });
    }
  };
}

export function getBookChapterDetail(chapter_id: string) {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makeGetApi(`/api/v1/book/chapter/${chapter_id}`);
    try {
      dispatch({
        type: ActionType.GET_BOOK_CHAPTER_DETAIL,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_BOOK_CHAPTER_DETAIL_FAIL,
        payload: response.data.message,
      });
    }
  };
}

export function getBookChapters(book_id: string, limit: number = 200, page: number = 1, sortValue: string) {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makeGetApi(`/api/v1/book/${book_id}/chapters`, {
      limit: limit,
      page: page,
      sortField: "index",
      sortType: sortValue,
    });
    try {
      dispatch({
        type: ActionType.GET_BOOK_CHAPTERS,
        payload: response.data.data?.items,
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_BOOK_CHAPTERS,
        payload: [],
      });
    }
  };
}