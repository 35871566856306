import React, { FC } from "react";
import convertNumbThousand from "utils/convertNumbThousand";
import { Icon } from "@iconify/react";

export interface PostCardViewDisplayProps {
  className?: string;
  count?: number;
}

const PostCardViewDisplay: FC<PostCardViewDisplayProps> = ({
                                                             className = "px-3 h-8 text-xs",
                                                             count,
                                                           }) => {
  return (
    <button
      className={`nc-PostCardLikeAction relative min-w-[68px] flex items-center rounded-full leading-none group transition-colors ${className}
        text-neutral-700 bg-neutral-50 dark:text-neutral-200 dark:bg-neutral-800 hover:bg-rose-50 dark:hover:bg-rose-100 hover:text-rose-600 dark:hover:text-rose-500`}
      title="Like count"
    >
      <Icon icon="mdi:eye-outline" style={{
        fontSize: "20px",
      }} />
      &nbsp;&nbsp;
      {count !== undefined && (
        <span
          className={`ml-1 text-neutral-900 dark:text-neutral-200`}
        >{convertNumbThousand(count)}
        </span>
      )}
    </button>
  );
};

export default PostCardViewDisplay;
