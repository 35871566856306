import axios from "axios";
import { HOST_API } from "../config";

console.log("HOST_API", HOST_API, process.env);


// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || "Lỗi hệ thống. Xin thử lại sau"),
);

export default axiosInstance;
