import React, { HTMLAttributes, ReactNode } from "react";
import Link from "../Link";
import { Icon } from "@iconify/react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Button from "../../components/Button/Button";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  isCenter?: boolean;
  moreLink?: string;
}

const Heading: React.FC<HeadingProps> = ({
                                           children,
                                           desc = "",
                                           className = "mb-10 md:mb-12 text-neutral-900 dark:text-neutral-50",
                                           isCenter = false,
                                           moreLink,
                                           ...args
                                         }) => {
  return (
    <div
      className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div
        className={
          isCenter ? "text-center w-full max-w-2xl mx-auto " : "heading-view-all"
        }
      >
        <h2
          className={`text-2xl md:text-3xl lg:text-4xl font-semibold inline`}
          {...args}
        >
          {children || `Section Heading`}
        </h2>
        {moreLink && (
          <Button className="!hidden md:!flex" pattern="white" sizeClass="px-6" href={moreLink}>
            <span>Xem thêm</span>
            <ArrowRightIcon className="w-6 h-6 ml-3" />
          </Button>
        )}
        {desc && (
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            {desc}
          </span>
        )}
      </div>
    </div>
  )
    ;
};

export default Heading;
