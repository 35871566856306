import CardPodCastItem from "./CardPodCastItem";
import Heading from "_components/Heading/Heading";
import React, { FC } from "react";
import { BookItem } from "../../type";

export interface SectionListItemProps {
  books?: BookItem[];
  className?: string;
  heading?: string;
  moreLink?: string;
}

const SectionListPodCastItem: FC<SectionListItemProps> = ({
                                                            books = [],
                                                            className = "",
                                                            heading,
                                                            moreLink,
                                                          }) => {
  return (
    <div className={`nc-SectionMagazine8 relative ${className}`}>
      {!!heading && (
        <Heading
          className="mb-14 text-neutral-900 dark:text-neutral-50"
          moreLink={moreLink}
        >
          {heading}
        </Heading>
      )}
      <div className={`grid grid-cols-1 sm:grid-cols-9 xl:grid-cols-8 gap-8 md:gap-8`}>
        {books.map((book, index) => (
          <CardPodCastItem
            key={index}
            className="sm:col-span-3 lg:col-span-3 xl:col-span-2 xs:col-span-3"
            book={book}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionListPodCastItem;
