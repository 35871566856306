import React, { useEffect, useState } from "react";
import Pagination from "_components/Pagination/Pagination";
import { makePostApi } from "utils/api";
import { BookItem, PaginationInfo } from "type";
import SectionListAudioItem from "_components/SectionListAudioItem/SectionListAudioItem";
import GetQueryParam from "../../utils/getQueryParam";
import { APP_TITLE } from "../../config";

const PageSearch = () => {
  let keyword = GetQueryParam("keyword") || ""
  let page = parseInt(GetQueryParam("page") || "1");
  const [books, setBooks] = useState<BookItem[]>([]);
  const [pageInfo, setPageInfo] = useState<PaginationInfo>({
    limit: 0, page: 0, total: 0, totalPage: 0,
  });
  const getBooks = async (page: number) => {
    const response = await makePostApi("/api/v1/books/filter", {
      limit: 24,
      keyword: keyword,
      page: page,
    });
    setBooks(response.data?.data?.items);
    setPageInfo(response.data?.data?.extra?.paging);
  };
  useEffect(() => {
    getBooks(page);
    document.title = "Tìm kiếm: " + keyword + APP_TITLE;
  }, [keyword, page]);
  return (
    <div className={`nc-PageArchive`}>
      <div className="container py-10 lg:py-10 lg:space-y-10">
        <main>
          <SectionListAudioItem
            heading="Danh sách Audio"
            className="py-10 lg:py-10"
            books={books}
          />
          {/* PAGINATION */}
          <div
            className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination current={page} total={pageInfo.totalPage} formatLink={`/search/?keyword=${keyword}&page={page}`} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageSearch;
