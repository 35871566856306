import CardAudioItem from "./CardAudioItem";
import Heading from "_components/Heading/Heading";
import React, { FC } from "react";
import { BookItem } from "../../type";
import Link from "../Link";

export interface SectionListItemProps {
  books?: BookItem[];
  className?: string;
  heading?: string;
  moreLink?: string;
}

const SectionListAudioItem: FC<SectionListItemProps> = ({
                                                          books = [],
                                                          className = "",
                                                          heading,
                                                          moreLink,
                                                        }) => {
  return (
    <div className={`nc-SectionMagazine8 relative ${className}`}>
      {!!heading && (
        <>
          <Heading
            className="mb-14 text-neutral-900 dark:text-neutral-50 inline"
            moreLink={moreLink}
          >
            {heading}
          </Heading>
        </>
      )}
      <div
        className="grid grid-cols-2 gap-8
      sm:grid-cols-1
      xs:grid-cols-12
      xs:gap-2
      md:grid-cols-4
      md:gap-4
      xl:grid-cols-12 xl:gap-3
      lg:grid-cols-12 lg:gap-3"
      >
        {books.map((book, index) => (
          <CardAudioItem
            key={index}
            ratio="aspect-w-4 aspect-h-4"
            className="sm:col-span-1 lg:col-span-3 xl:col-span-2 xs:col-span-3"
            book={book}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionListAudioItem;
