import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";
import menuReducer from "./slices/menu";
import homeReducer from "./slices/homepage";
import bookReducer from "./slices/book";
import userSettingReducer from "./slices/user_settings";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-vradio-",
  whitelist: [],
};

const userPersistConfig = {
  key: "user",
  storage,
  keyPrefix: "redux-vradio-",
};

const menuPersistConfig = {
  key: "menu",
  storage,
  keyPrefix: "redux-vradio-",
};

const homepagePersistConfig = {
  key: "homepage",
  storage,
  keyPrefix: "redux-vradio-",
};

const bookPersistConfig = {
  key: "book",
  storage,
  keyPrefix: "redux-vradio-",
};

const userSettingPersistConfig =  {
  key: "user_settings",
  storage,
  keyPrefix: "redux-vradio-",
}

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  menu: persistReducer(menuPersistConfig, menuReducer),
  homepage: persistReducer(homepagePersistConfig, homeReducer),
  book: persistReducer(bookPersistConfig, bookReducer),
  userSettings: persistReducer(userSettingPersistConfig, userSettingReducer),
});

export { rootPersistConfig, rootReducer };
