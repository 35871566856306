import React, { FC } from "react";

export interface TabListProps {
  className?: string;
  itemClass?: string;
  lists?: { name: string, key: string }[];
  defaultIndexSelected?: number;
  onSelect?: (index: number) => void;
}

const TabList: FC<TabListProps> = ({
                                     className,
                                     itemClass,
                                     lists,
                                     defaultIndexSelected = 0,
                                     onSelect,
                                   }) => {
  const handleSelect = (index: number) => {
    if (onSelect) {
      onSelect(index);
    }
  };
  return (
    <div className={`tabs text-sm rounded-tr-2xl rounded-tl-2xl 
    text-neutral-900 dark:text-neutral-200 border-b border-neutral-200 dark:border-neutral-700
    focus:outline-none z-50 bg-white/40 dark:bg-neutral-800 shadow-lg" ${className || ""}`}>
      {lists?.map((item, index: number) => (
        <span
          key={index}
          className={`tab-label cursor-default select-none relative py-2 pl-10 pr-4 hover:bg-neutral-200 dark:hover:bg-neutral-700
          first:rounded-tl-2xl
          ${defaultIndexSelected === index ? "bg-secondary-400": ""} 
          ${itemClass || ""}`}
          onClick={() => handleSelect(index)}
        >
          {item.name}
        </span>
      ))}
    </div>
  );
};

export default TabList;
