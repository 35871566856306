export type Action = {
  type: string;
  payload: any | string;
};

export enum ActionType {
  // User
  USER_GET_ANONYMOUS_TOKEN = "USER_GET_ANONYMOUS_TOKEN",
  USER_GET_ANONYMOUS_TOKEN_FAIL = "USER_GET_ANONYMOUS_TOKEN_FAIL",
  USER_GET_PROFILE = "USER_GET_PROFILE",
  USER_GET_PROFILE_FAIL = "USER_GET_PROFILE_FAIL",

  // Menu
  GET_MENUS = "GET_MENUS",
  GET_MENU_FAIL = "GET_MENU_FAIL",

  // Homepage
  GET_HOME_HOT_BANNER = "GET_HOME_HOT_BANNER",
  GET_NEWEST_AUDIO_BOOK = "GET_NEWEST_AUDIOBOOK",
  GET_NEWEST_AUDIO_STORY = "GET_NEWEST_AUDIO_STORY",
  GET_NEWEST_AUDIO_PODCAST = "GET_NEWEST_AUDIO_PODCAST",
  GET_NEWEST_AUDIO_RADIO = "GET_NEWEST_AUDIO_RADIO",
  GET_NEWEST_AUDIO_NGON_TINH = "GET_NEWEST_AUDIO_NGON_TINH",
  GET_NEWEST_AUDIO_TRUYEN_MA = "GET_NEWEST_AUDIO_TRUYEN_MA",
  GET_NEWEST_AUDIO_LIVE = "GET_NEWEST_AUDIO_LIVE",


  // Book
  GET_BOOK_DETAIL = "GET_BOOK_DETAIL",
  GET_BOOK_DETAIL_FAIL = "GET_BOOK_DETAIL_FAIL",
  GET_BOOK_CHAPTER_DETAIL = "GET_BOOK_CHAPTER_DETAIL",
  GET_BOOK_CHAPTER_DETAIL_FAIL = "GET_BOOK_DETAIL_FAIL",
  GET_BOOK_CHAPTERS = "GET_BOOK_CHAPTERS",

  // User settings
  SET_USER_SETTINGS = "SET_USER_SETTINGS",
  RESET_USER_SETTINGS = "RESET_USER_SETTINGS",
  SET_USER_PLAYED_TIME = "SET_USER_PLAYED_TIME",
}