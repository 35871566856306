import React, { useEffect, useState } from "react";
import Image from "_components/Image";
import SingleHeader from "./SingleHeader";
import Tag from "_components/Tag/Tag";
import { TagInfo } from "../../type";
import WidgetDetailInfo from "./WidgetDetailInfo";
import WidgetBookChapters from "./WidgetBookChapters";
import WidgetBookRelated from "./WidgetBookRelated";
import WidgetBookOtherVoice from "./WidgetBookOtherVoice";
import WidgetBookSameVoice from "./WidgetBookSameVoice";
import { setUserSettings } from "redux/slices/user_settings";
import { settingKeys } from "../../constants";
import { useDispatch } from "redux/store";
import { useSelector } from "react-redux";
import FbComment from "../../_components/FBComment";
import TabList from "../../_components/TabList/TabList";

const PageBookDetailList = () => {
  const dispatch = useDispatch();
  const {
    book_detail,
  } = useSelector((state: any) => state.book);

  const {
    settings,
  } = useSelector((state: any) => state.userSettings);
  const tabLists = [
    { name: "Giới thiệu", key: "info" },
    { name: "Danh sách audio", key: "chapters" },
  ];
  const [tabSelected, setTabSelected] = useState(0);
  return (
    <div className={`relative `}>
      <title>{book_detail?.name}</title>
      <header className="relative pt-16 z-10 md:py-20 lg:py-28 bg-neutral-900 dark:bg-black">
        {/* SINGLE HEADER */}
        <div className="dark container relative z-10">
          <div className="max-w-screen-md">
            <SingleHeader book={book_detail || {}} hiddenDesc />
          </div>
        </div>

        {/* FEATURED IMAGE */}
        <div className="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3">
          <div
            className="hidden md:block absolute top-0 left-0 bottom-0 w-1/5 from-neutral-900 dark:from-black bg-gradient-to-r"></div>
          <Image
            className="block w-full h-full object-cover"
            src={book_detail?.cover}
            alt=""
            width={1635}
            height={774}
            sizes="(max-width: 1024px) 100vw, 1240px"
          />
        </div>
      </header>

      <div className="container flex flex-col my-10 lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
          <div className="relative">
            <TabList lists={tabLists}
                     defaultIndexSelected={tabSelected}
                     onSelect={(index) => {
                       setTabSelected(index);
                     }}
            />
            <div className="p-4 bg-white/10 dark:bg-neutral-800 shadow-lg">
              <WidgetBookChapters className={tabLists[tabSelected]?.key === "chapters" ? "" : "hidden"}
                                  book={book_detail}
                                  defaultSort={settings[settingKeys.defaultChapterSort] || "desc"}
                                  onChangeSort={(value) => {
                                    dispatch(setUserSettings(settingKeys.defaultChapterSort, value));
                                  }} />
              <div className={`nc-SingleContent space-y-10 ${tabLists[tabSelected]?.key === "info" ? "" : "hidden"}`}>
                {book_detail?.description && (
                  <div className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert">
                    <blockquote dangerouslySetInnerHTML={{ __html: book_detail?.description }} />
                  </div>
                )}
                <div className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert" style={{
                  textAlign: "justify",
                }}>
                  <div style={{
                    lineHeight: 2,
                  }} dangerouslySetInnerHTML={{ __html: book_detail?.content || "" }} />
                </div>
              </div>
            </div>
            <div className=" mt-10 p-4 bg-white/10 dark:bg-neutral-800 shadow-lg">
              {/* TAGS */}
              <div className="max-w-screen-md mx-auto flex flex-wrap">
                {book_detail?.tag?.map((item: TagInfo) => (
                  <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
                ))}
              </div>
            </div>
            {/* FACEBOOK COMMENT */}
            <div className=" mt-10 p-4 bg-white/10 dark:bg-neutral-800 shadow-lg">
              <div className={`container text-center`}>
                <FbComment url={book_detail?.url} />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3">
          <div className={`nc-SingleSidebar space-y-6`}>
            <WidgetDetailInfo book={book_detail || {}} />
            <WidgetBookOtherVoice book={book_detail || {}} />
            <WidgetBookRelated book={book_detail || {}} />
            <WidgetBookSameVoice book={book_detail || {}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBookDetailList;
