import axios from "../utils/axios";

export function getAccessToken() {
  return localStorage.getItem("vradio-accessToken");
}

export const METHOD_API = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export function makeCallApi(url, method, configs = {}) {
  configs["headers"] = {
    ...configs["headers"],
    Authorization: getAccessToken(),
  };
  let obj = {
    url: url,
    method: method,
    ...configs,
  };
  let axios_config = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  return axios(axios_config);
}

export function makePostApi(url, data = {}, configs = {}) {
  configs = {
    ...configs,
    data: data,
  };
  return makeCallApi(url, METHOD_API.POST, configs);
}

export function makeGetApi(url, params = {}, configs = {}) {
  configs = {
    ...configs,
    params: params,
  };
  return makeCallApi(url, METHOD_API.GET, configs);
}

export function makePutApi(url, data = {}, configs = {}) {
  configs = {
    ...configs,
    data: data,
  };
  return makeCallApi(url, METHOD_API.PUT, configs);
}

export function makeDeleteApi(url, configs = {}) {
  return makeCallApi(url, METHOD_API.DELETE, configs);
}

export function makeDownloadFromApi(url, file_name = {}, configs = {}) {
  makeCallApi(url, METHOD_API.GET, {
    responseType: "blob",
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;

    link.download = file_name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}