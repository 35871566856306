import React from "react";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import Link from "_components/Link";

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({
                                     img = logoImg,
                                     imgLight = logoLightImg,
                                   }) => {
  return (
    <Link
      href="/"
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
    >
      <img src={"/static/logo.svg"} style={{
        width: "80%",
      }} alt="vRadio.Me" />
    </Link>
  );
};

export default Logo;
