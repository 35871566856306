import React, { FC, useEffect, useState } from "react";
import { BookItem, TagInfo } from "type";
import WidgetHeading from "_components/WidgetHeading/WidgetHeading";
import WidgetCardBookRelated from "_components/WidgetCardBookRelated/WidgetCardBookRelated";
import { makePostApi } from "../../utils/api";

interface WidgetBookSameVoiceProps {
  className?: string;
  book?: BookItem;
}


const WidgetBookSameVoice: FC<WidgetBookSameVoiceProps> = ({
                                                             className = "bg-neutral-100 dark:bg-neutral-800",
                                                             book = {} as BookItem,
                                                           }) => {
  const [bookRelated, setBookRelated] = useState([]);
  const getBookRelated = async () => {
    if (book?.id === undefined) {
      return;
    }
    if (book?.speaker === undefined || book?.speaker?.length === 0) {
      return;
    }
    const response = await makePostApi(`/api/v1/books/${book?.id}/related/speaker`, {
      tagIds: book?.speaker?.map((item: TagInfo) => item.id),
      limit: 5,
    });
    setBookRelated(response.data?.data?.items);
  };

  useEffect(() => {
    if (book?.id === undefined) {
      return;
    }
    getBookRelated();
  }, [book]);

  return (
    <div
      className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
    >
      {bookRelated.length === 0 ? null : (
        <>
          <WidgetHeading
            title="✨ Cùng giọng đọc"
          />
          <div className="flow-root">
            <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
              {bookRelated?.map((book: BookItem) => (
                <WidgetCardBookRelated
                  className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                  key={book.id}
                  book={book}
                  size="normal"
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WidgetBookSameVoice;
