import React, { FC, useState } from "react";
import { Icon } from "@iconify/react";
import Link from "components/Link";
import Image from "components/Image";
import { BookItem } from "../../type";
import { useMusicPlayer } from "../../hooks/useMusicPlayer";

export interface PostFeaturedMediaProps {
  className?: string;
  book: BookItem;
  isHover?: boolean;
}

const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({
                                                         className = "w-full h-full",
                                                         book,
                                                         isHover = false,
                                                       }) => {
  const {
    setQuickBookId,
    setPlaying,
  } = useMusicPlayer();

  const { poster, url } = book;
  const [imageError, setImageError] = useState(false);
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!imageError) {
      const imageElement = event.target as HTMLImageElement;
      imageElement.src = "//tranvu.info/logo/vradio_logo_4_3.png"; // Display a fallback image
      setImageError(true);
    }
  };

  const handlePlayClick = () => {
    setQuickBookId(book.id);
    setPlaying(true);
  }
  return (
    <div className={`nc-PostFeaturedMedia relative ${className}`}>
      <Image
        alt="featured"
        fill
        className="object-cover"
        src={poster}
        sizes="(max-width: 600px) 480px, 800px"
        onError={handleImageError}
      />
      <div className="flex items-center justify-center m-button-listen">
        <div
          title={"Nghe nhanh"}
          onClick={handlePlayClick}
          className="text-white bg-black bg-blend-multiply bg-opacity-50 w-20 h-20 border-2 border-neutral-300 rounded-full flex items-center justify-center ">
          <Icon icon={"bi:play-fill"} className="ml-0.5 w-7 h-7" />
        </div>
      </div>
      <Link
        href={url}
        className={`block absolute inset-0 bg-black/20 transition-opacity opacity-0 group-hover:opacity-100`}
      />
    </div>
  );
};

export default PostFeaturedMedia;
