import React, { FC } from "react";
import NavigationItem from "./NavigationItem";
import { useSelector } from "react-redux";
import { NavItemType } from "type";
interface Props {
  className?: string;
}

const Navigation: FC<Props> = ({ className = "flex" }) => {
  const { header } = useSelector((state: any) => state.menu);
  return (
    <ul className={`nc-Navigation items-center ${className}`}>
      {header.map((item: NavItemType) => [
        <NavigationItem key={item.id} menuItem={item} />,
      ])}
    </ul>
  );
};

export default Navigation;
