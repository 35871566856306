import React, { FC } from "react";
import CategoryBadgeList from "_components/CategoryBadgeList/CategoryBadgeList";
import SingleTitle from "../SingleTitle";
import PostMeta from "./PostMeta";
import SingleMetaAction from "./SingleMetaAction";
import { BookItem } from "../../type";

export interface SingleHeaderProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
  book: BookItem;
}

const SingleHeader: FC<SingleHeaderProps> = ({
                                               titleMainClass,
                                               hiddenDesc = false,
                                               className = "",
                                               book,
                                             }) => {
  return (
    <>
      <div className={`nc-SingleHeader ${className}`}>
        <div className="space-y-5">
          <CategoryBadgeList
            itemClass="!px-3"
            categories={book.category}
          />
          <SingleTitle
            mainClass={titleMainClass}
            title={book.name}
          />
          {!hiddenDesc && (
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1"
            dangerouslySetInnerHTML={
                    { __html: book.description }
                  }>
            </span>
          )}
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
            <PostMeta
              size="large"
              className="leading-none flex-shrink-0"
              hiddenCategories
              avatarRounded="rounded-full shadow-inner"
            />
            <SingleMetaAction book={book} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleHeader;
