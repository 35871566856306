import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Pagination from "_components/Pagination/Pagination";
import { makeGetApi, makePostApi } from "utils/api";
import { BookItem, PaginationInfo, TagInfo } from "type";
import SectionListAudioItem from "_components/SectionListAudioItem/SectionListAudioItem";
import Image from "_components/Image";
import convertNumbThousand from "../../utils/convertNumbThousand";
import { APP_TITLE } from "../../config";

const PageCategory = () => {
  const { tagSlug = "" } = useParams();
  const { page = 1 } = useParams();
  const pageNumber = parseInt(String(page));
  const [books, setBooks] = useState<BookItem[]>([]);
  const [tag, setTag] = useState<TagInfo>({
    avatar: "",
    cover: undefined,
    description: "",
    id: undefined,
    name: "",
    url: "",
  });
  const [pageInfo, setPageInfo] = useState<PaginationInfo>({
    limit: 0, page: 0, total: 0, totalPage: 0,
  });
  const getBooks = async (page: number) => {
    const response = await makePostApi("/api/v1/books/filter", {
      limit: 24,
      tagIds: [tag.id],
      page: page,
    });
    setBooks(response.data?.data?.items);
    setPageInfo(response.data?.data?.extra?.paging);
  };
  const getTag = async () => {
    const response = await makeGetApi(`/api/v1/tag/${tagSlug}`);
    if (response.status === 200) {
      setTag(response.data?.data);
    }
  };

  useEffect(() => {
    getTag();
  }, [tagSlug]);

  useEffect(() => {
    if (tag.id === undefined) return;
    getBooks(pageNumber);
    document.title = tag.name + APP_TITLE;
  }, [tag, pageNumber]);
  return (
    <div className={`nc-PageArchive`}>
      {tag && (
        <div className="w-full px-2 xl:max-w-screen-2xl mx-auto py-5">
          <div
            className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
            <Image
              fill
              src={tag.cover}
              className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
              sizes="(max-width: 1280px) 100vw, 1536px"
            />
            <div
              className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
              <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
                {tag.name}
              </h2>
              <span className="block mt-4 text-neutral-300">{convertNumbThousand(pageInfo.total)} audios</span>
            </div>
          </div>
        </div>
      )}
      <div className="container py-10 lg:py-10 lg:space-y-10">
        <main>
          <SectionListAudioItem
            heading="Danh sách Audio"
            className="py-10 lg:py-10"
            books={books}
          />
          {/* PAGINATION */}
          <div
            className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination current={pageNumber} total={pageInfo.totalPage} formatLink={`${tag.url}/{page}`} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageCategory;
