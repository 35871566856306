import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { getBookChapterDetail, getBookDetail } from "redux/slices/book";
import { APP_TITLE } from "../../config";
import PageChapterAudio from "./chapter_audio";

const PageChapterDetail = () => {
  const dispatch = useDispatch();
  const { chapterSlug = "" } = useParams();
  const {
    chapter_detail,
  } = useSelector((state: any) => state.book);
  useEffect(() => {
    if (chapter_detail?.id === undefined || chapter_detail?.id !== chapterSlug) {
      dispatch(getBookChapterDetail(chapterSlug));
    }
  }, [chapterSlug]);

  useEffect(() => {
    if (chapter_detail?.bookId === undefined) {
      return;
    }
    dispatch(getBookDetail(chapter_detail.bookId));
    document.title = chapter_detail?.name + APP_TITLE;
  }, [chapter_detail]);
  if (chapter_detail?.chapterType === "audio") {
    return <PageChapterAudio />;
  }
  return (
    <div className={`relative `}>
    </div>
  );
};

export default PageChapterDetail;
