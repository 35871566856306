import React, { FC } from "react";
import twFocusClass from "utils/twFocusClass";
import Link from "_components/Link";

interface PaginationItem {
  name: number | string;
  url: string;
  active?: boolean;
}

function generatePagination(currentPage: number, totalPages: number, formatLink: string): PaginationItem[] {
  const pagination: PaginationItem[] = [];

  // Hiển thị các trang đầu
  for (let page = 1; page <= Math.min(3, totalPages); page++) {
    pagination.push({
      name: page,
      url: formatLink.replace("{page}", String(page)),
      active: page === currentPage,
    });
  }

  if (currentPage > 5) {
    pagination.push({ name: "...", url: "", active: false });
  }

  // Hiển thị các trang liền kề và trang hiện tại
  const startPage = Math.max(4, currentPage - 2);
  const endPage = Math.min(totalPages - 3, currentPage + 2);

  for (let page = startPage; page <= endPage; page++) {
    pagination.push({
      name: page,
      url: formatLink.replace("{page}", String(page)),
      active: page === currentPage,
    });
  }

  if (currentPage < totalPages - 4) {
    pagination.push({ name: "...", url: "", active: false });
  }

  // Hiển thị các trang cuối
  for (let page = Math.max(totalPages - 2, 4); page <= totalPages; page++) {
    pagination.push({
      name: page,
      url: formatLink.replace("{page}", String(page)),
      active: page === currentPage,
    });
  }

  return pagination;
}


export interface PaginationProps {
  formatLink?: string;
  className?: string;
  buttonSizeClass?: string;
  current?: number;
  total?: number;
  onClick?: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
                                           className = "",
                                           buttonSizeClass = "",
                                           formatLink = "",
                                           current = 1,
                                           total = 0,
                                           onClick,
                                         }) => {
  if (total <= 1) return <></>;
  const pagination: PaginationItem[] = generatePagination(current, total, formatLink);

  const handleClick = (page: number | string) => {
    if (onClick) {
      onClick(parseInt(typeof page === "string" ? page : page.toString()));
    }
  };
  const renderItem = (pag: PaginationItem, index: number) => {
    if (pag.active) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex ${buttonSizeClass ? buttonSizeClass : "pt-2 pb-2 pr-4 pl-4"} items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pag.name}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        key={index}
        onClick={() => pag.name !== "..." && handleClick(pag.name)}
        className={`inline-flex items-center ${buttonSizeClass ? buttonSizeClass : "pt-2 pb-2 pr-4 pl-4"} justify-center rounded-full bg-white hover:bg-neutral-100 border
        border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}  ${buttonSizeClass}`}
        href={pag.url}
      >
        {pag.name}
      </Link>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {pagination.map(renderItem)}
    </nav>
  );
};

export default Pagination;
