"use client";
import { createGlobalState } from "react-hooks-global-state";
import { BookItem, ChapterItem } from "../type";
import { useEffect } from "react";
import { setUserPlayedTime, setUserSettings } from "../redux/slices/user_settings";
import { settingKeys } from "../constants";
import { useDispatch } from "../redux/store";
import { useSelector } from "react-redux";

const initialState: {
  playing: boolean;
  volume: number;
  muted: boolean;
  played: number;
  playedSeconds: number;
  loaded: number;
  duration: number;
  loadedSeconds: number;
  playbackRate: number;
  bookData?: BookItem;
  chapterData: ChapterItem[];
  currentChapterId?: string;
  currentBookId?: string;
  audioUrl?: string;
  backupAudioUrls: string[];
  seekTo: number;
  quickBookId?: string;
} = {
  playing: false,
  volume: 0.8,
  muted: false,
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  duration: 0,
  loadedSeconds: 0,
  playbackRate: 1.0,
  bookData: undefined,
  chapterData: [],
  currentChapterId: undefined,
  currentBookId: undefined,
  audioUrl: undefined,
  backupAudioUrls: [],
  seekTo: 0,
  quickBookId: undefined,
};

const { useGlobalState } = createGlobalState(initialState);

export const useMusicPlayer = () => {
  const [playbackRate, setPlaybackRate] = useGlobalState("playbackRate");
  const [duration, setDuration] = useGlobalState("duration");
  const [loaded, setLoaded] = useGlobalState("loaded");
  const [played, setPlayed] = useGlobalState("played");
  const [muted, setMuted] = useGlobalState("muted");
  const [volume, setVolume] = useGlobalState("volume");
  const [playing, setPlaying] = useGlobalState("playing");
  const [loadedSeconds, setLoadedSeconds] = useGlobalState("loadedSeconds");
  const [playedSeconds, setPlayedSeconds] = useGlobalState("playedSeconds");
  const [bookData, setBookData] = useGlobalState("bookData");
  const [chapterData, setChapterData] = useGlobalState("chapterData");
  const [currentChapterId, setCurrentChapterId] = useGlobalState("currentChapterId");
  const [currentBookId, setCurrentBookId] = useGlobalState("currentBookId");
  const [audioUrl, setAudioUrl] = useGlobalState("audioUrl");
  const [backupAudioUrls, setBackupAudioUrls] = useGlobalState("backupAudioUrls");
  const [seekTo, setSeekTo] = useGlobalState("seekTo");
  const [quickBookId, setQuickBookId] = useGlobalState("quickBookId");
  const dispatch = useDispatch();
  const {
    settings,
  } = useSelector((state: any) => state.userSettings);

  useEffect(() => {
    if (currentChapterId) {
      dispatch(setUserSettings(settingKeys.currentChapterId, currentChapterId));
      setSeekTo(0);
      let playedTime = settings[settingKeys.playedTime] && settings[settingKeys.playedTime][currentChapterId] ? settings[settingKeys.playedTime][currentChapterId] : 0;
      // console.log("playedTime", playedTime)
      if (playedTime > 0) {
        setSeekTo(playedTime);
      }
    }
    if (currentBookId) {
      dispatch(setUserSettings(settingKeys.currentBookId, currentBookId));
    }

  }, [currentChapterId, currentBookId]);

  useEffect(() => {
    // console.log("playedSeconds", playedSeconds, currentChapterId, duration - playedSeconds, playedSeconds > 60)
    if (currentChapterId) {
      if (playedSeconds > 10 && duration - playedSeconds < 60) {
        dispatch(setUserPlayedTime(currentChapterId, null));
      } else if (playedSeconds > 60) {
        dispatch(setUserPlayedTime(currentChapterId, playedSeconds));
      }
    }
  }, [playedSeconds]);
  return {
    duration,
    playedSeconds,
    setPlayedSeconds,
    loadedSeconds,
    setLoadedSeconds,
    setDuration,
    loaded,
    setLoaded,
    played,
    setPlayed,
    muted,
    setMuted,
    volume,
    setVolume,
    playing,
    setPlaying,
    playbackRate,
    setPlaybackRate,
    currentChapterId,
    setCurrentChapterId,
    currentBookId,
    setCurrentBookId,
    bookData,
    setBookData,
    chapterData,
    setChapterData,
    audioUrl,
    setAudioUrl,
    backupAudioUrls,
    setBackupAudioUrls,
    seekTo,
    setSeekTo,
    quickBookId,
    setQuickBookId,
  };
};
