import React from "react";
import iconPlaying from "images/icon-playing.gif";
import Image from "_components/Image";
import ButtonPlayMusicPlayer from "_components/ButtonPlayMusicPlayer";
import SingleTitle from "../SingleTitle";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { TagInfo } from "../../type";
import Tag from "../../_components/Tag/Tag";
import Link from "../../components/Link";
import FbComment from "../../_components/FBComment";

const PageChapterAudio = () => {
  const {
    chapter_detail,
    book_detail,
  } = useSelector((state: any) => state.book);
  const renderIcon = (playing: boolean) => {
    if (playing) {
      return <Image className="w-7" src={iconPlaying} alt="" />;
    }

    return (
      <Icon icon={"bi:play-fill"} className="ml-0.5 w-7 h-7" />
    );
  };

  const renderButtonPlay = (playing: boolean) => {
    return (
      <div
        className={`aspect-w-1 aspect-h-1 rounded-full overflow-hidden z-10 shadow-2xl group cursor-pointer`}
      >
        <Image
          className={`w-full h-full object-cover transition-transform z-0 nc-animation-spin rounded-full ${
            playing ? "playing" : ""
          }`}
          src={chapter_detail.poster}
          alt="audio"
        />

        <div className="bg-neutral-900 bg-blend-multiply bg-opacity-75 rounded-full"></div>
        <div className="flex items-center justify-center">
          <div
            className="text-white bg-black bg-blend-multiply bg-opacity-50 w-20 h-20 border-2 border-neutral-300 rounded-full flex items-center justify-center ">
            {renderIcon(playing)}
          </div>
        </div>
      </div>
    );
  };

  const renderTagInfo = (name: string, icon: string, tagInfo: TagInfo[]) => {
    if (!tagInfo) return null;
    return <div className="flex items-center space-x-2 mb-3">
            <span className="text-neutral-600 dark:text-neutral-400 text-sm">
              <Icon icon={icon} className={"inline-flex"} /> {name}
            </span>
      <span className="text-neutral-700 dark:text-neutral-300 text-sm">
              {tagInfo?.map((item: TagInfo, index: number) => (
                <Link key={index} href={item?.url}>{item?.name}</Link>
              ))}
            </span>
    </div>;
  };

  return (
    <div className={`relative pt-8 lg:pt-16`}>
      {/* Overlay */}
      <div className="bg-primary-50 dark:bg-neutral-800 absolute top-0 inset-x-0 h-60 w-full"></div>

      {/* SINGLE_AUDIO HEADER */}
      <header className="relative container ">
        <div
          className="bg-white dark:bg-neutral-900 shadow-2xl px-5 py-7 md:p-11 rounded-2xl md:rounded-[40px] flex flex-col sm:flex-row items-center justify-center space-y-10 sm:space-y-0 sm:space-x-11">
          <div className="w-1/2 sm:w-1/4 flex-shrink-0">
            <ButtonPlayMusicPlayer
              renderChildren={renderButtonPlay}
              chapterId={chapter_detail.id}
            />
          </div>
          <div className="flex flex-col space-y-5">
            <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
              {/*<div>*/}
              {/*  <Badge name="S1 EP. 128" />*/}
              {/*</div>*/}
              <span className="text-neutral-500 dark:text-neutral-400">
                  <span className="mx-2">·</span>
                {chapter_detail.publishDate || chapter_detail.sortDate}
                </span>
            </div>
            <SingleTitle title={chapter_detail.name} />
            <div className="hidden lg:block text-lg text-neutral-500 dark:text-neutral-400"
            >
              <div className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert">
                <div className="flex flex-wrap p-4 xl:p-5">
                  <div className="w-full">
                    {renderTagInfo("Tác giả: ", "carbon:user-avatar-filled", book_detail?.author!)}
                    {renderTagInfo("Thể loại: ", "carbon:category", book_detail?.category!)}
                    {renderTagInfo("Giọng đọc: ", "icon-park-solid:voice", book_detail?.speaker!)}
                    {renderTagInfo("Nhà xuất bản: ", "fluent-mdl2:publish-course", book_detail?.publisher!)}
                    {renderTagInfo("Quốc gia: ", "ion:earth-sharp", book_detail?.country!)}
                    {renderTagInfo("Sách: ", "ion:book", [book_detail]!)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        {/*<div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">*/}
        <div className="relative">
          <div className="nc-SingleContent space-y-10">
            {chapter_detail?.description && (
              <div className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert">
                <blockquote dangerouslySetInnerHTML={{ __html: chapter_detail?.description }} />
              </div>
            )}
            <div className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert" style={{
              textAlign: "justify",
            }}>
              <div style={{
                lineHeight: 2,
              }} dangerouslySetInnerHTML={{ __html: chapter_detail?.content || book_detail?.content || "" }} />
            </div>

            {/* TAGS */}
            <div className="max-w-screen-md mx-auto flex flex-wrap">
              {book_detail?.tag?.map((item: TagInfo) => (
                <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
              ))}
            </div>
          </div>
        </div>
        <div className={`container text-center`}>
          <FbComment url={chapter_detail?.url} />
        </div>
        {/*</div>*/}
      </div>
    </div>
  );
};

export default PageChapterAudio;
