import React, { useEffect, useState } from "react";
import SectionHotBannerSlider from "./SectionHotBannerSlider";
import { useSelector } from "react-redux";
import {
  getHomePageBanner,
  getHomePageNewestAudioStory,
  getHomePageNewestAudioBook,
  getHomePageNewestAudioPodcast,
  getHomePageNewestRadio, getHomePageNewestAudioTruyenMa, getHomePageNewestAudioNgonTinh, getHomePageNewestAudioLive,
} from "redux/slices/homepage";
import { useDispatch } from "redux/store";
import SectionListPodCastItem from "_components/SectionListPodCastItem/SectionListPodCastItem";
import SectionListAudioItem from "_components/SectionListAudioItem/SectionListAudioItem";
import { APP_NAME } from "../../config";

const PageHome = () => {
  const dispatch = useDispatch();
  const {
    hot_banners,
    newest_audio_podcast,
    newest_audio_radio,
    newest_audio_book,
    newest_audio_ngon_tinh,
    newest_audio_truyen_ma,
    newest_audio_live,
  } = useSelector((state: any) => state.homepage);
  useEffect(() => {
    dispatch(getHomePageBanner());
    dispatch(getHomePageNewestAudioBook());
    dispatch(getHomePageNewestAudioPodcast());
    dispatch(getHomePageNewestRadio());
    dispatch(getHomePageNewestAudioTruyenMa());
    dispatch(getHomePageNewestAudioNgonTinh());
    dispatch(getHomePageNewestAudioLive());
    document.title = APP_NAME;
  }, []);
  return (
    <div className="nc-PageHome relative">
      <div className="container relative">
        <SectionHotBannerSlider
          className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
          posts={hot_banners}
        />
        {/*<SectionAds />*/}
      </div>
      <div className="container ">
        {newest_audio_live.length > 0 && <SectionListAudioItem
          moreLink={"/category/live-radio"}
          heading="Live Audio"
          className="py-10 lg:py-10"
          books={newest_audio_live}
        />}
        {newest_audio_book.length > 0 && <SectionListAudioItem
          moreLink={"/list/audiobook"}
          heading="Audio mới cập nhật"
          className="py-10 lg:py-10"
          books={newest_audio_book}
        />}
        {newest_audio_podcast.length > 0 && <SectionListPodCastItem
          heading="Podcast mới cập nhật"
          moreLink={"/list/podcast"}
          className="py-10 lg:py-10"
          books={newest_audio_podcast}
        />}
        {newest_audio_radio.length > 0 && <SectionListAudioItem
          heading="Radio mới cập nhật"
          moreLink={"/list/radio"}
          className="py-10 lg:py-10"
          books={newest_audio_radio}
        />}
        {newest_audio_ngon_tinh.length > 0 && <SectionListAudioItem
          heading="Sách ngôn tình mới"
          moreLink={"/tag/ngon-tinh"}
          className="py-10 lg:py-10"
          books={newest_audio_ngon_tinh}
        />}

        {newest_audio_truyen_ma.length > 0 && <SectionListAudioItem
          heading="Truyện ma mới"
          moreLink={"/tag/ma-kinh-di"}
          className="py-10 lg:py-10"
          books={newest_audio_truyen_ma}
        />}

        {/*{newest_audio_story.length > 0 && <SectionListAudioItem*/}
        {/*  heading="Truyện ngắn mới cập nhật"*/}
        {/*  className="py-10 lg:py-10"*/}
        {/*  books={newest_audio_story}*/}
        {/*/>}*/}
      </div>
    </div>
  );
};
;

export default PageHome;
