import React, { useEffect, useState } from "react";
import NcImage from "components/NcImage/NcImage";
import { useParams } from "react-router-dom";
import { BlogItem, TagInfo } from "../../type";
import { makeGetApi } from "../../utils/api";
import SingleTitle from "../../app/(singles)/SingleTitle";
import Tag from "_components/Tag/Tag";
import { APP_TITLE } from "../../config";

const PageBlogDetail = () => {
  const { blogSlug = "" } = useParams();
  const [blogContent, setBlogContent] = useState<BlogItem>({} as BlogItem);

  const getBlogDetail = async () => {
    const response = await makeGetApi(`/api/v1/blog/${blogSlug}`);
    setBlogContent(response.data?.data);
  };
  useEffect(() => {
    getBlogDetail();
  }, [blogSlug]);

  useEffect(() => {
    document.title = blogContent?.name + APP_TITLE;
  }, [blogContent]);
  return (
    <div>
      <div className={`nc-PageSingle pt-8 lg:pt-16`}>
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <div className={`nc-SingleHeader`}>
              <div className="space-y-5">
                <SingleTitle
                  title={blogContent.name}
                />
                {blogContent.description && (
                  <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1"
                        dangerouslySetInnerHTML={
                          { __html: blogContent.description }
                        }>
                  </span>
                )}
                {/*<div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>*/}
                {/*<div*/}
                {/*  className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">*/}
                {/*  <PostMeta2*/}
                {/*    size="large"*/}
                {/*    className="leading-none flex-shrink-0"*/}
                {/*    hiddenCategories*/}
                {/*    avatarRounded="rounded-full shadow-inner"*/}
                {/*  />*/}
                {/*  <SingleMetaAction2 />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </header>

        {blogContent.cover && <NcImage
          alt={blogContent.name}
          containerClassName="container my-10 sm:my-12"
          className="w-full rounded-xl"
          src={blogContent.cover}
          width={1260}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />
        }
      </div>
      {/* SINGLE MAIN CONTENT */}
      <div className="container mt-10">
        <div className="relative">
          <div className="nc-SingleContent space-y-10">
            {/* ENTRY CONTENT */}
            <div
              className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
              dangerouslySetInnerHTML={
                { __html: blogContent.content || "" }
              }
            >
            </div>

            {/* TAGS */}
            <div className="max-w-screen-md mx-auto flex flex-wrap">
              {blogContent.tag?.map((item: TagInfo) => (
                <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBlogDetail;
