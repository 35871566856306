import { Dispatch } from "react";
import { ActionType, Action } from "./actiontTypes";
import { makeGetApi } from "../../utils/api";

// ----------------------------------------------------------------------
interface UserSettingState {
  error: string | null;
  settings: any;
}

const initialState = {
  error: null,
  settings: {},
};

const userSettingReducer = (state: UserSettingState = initialState, action: Action): UserSettingState => {
  switch (action.type) {
    case ActionType.SET_USER_SETTINGS:
      let currentSettings = state.settings;
      currentSettings[action.payload.key] = action.payload.value;
      if (action.payload.value == null) {
        delete currentSettings[action.payload.key];
      }
      return {
        ...state,
        settings: currentSettings,
      };
    case ActionType.SET_USER_PLAYED_TIME:
      let currentPlayedTime = state.settings.playedTime || {};
      ;
      if (action.payload.value == null) {
        delete currentPlayedTime[action.payload.chapterId];
      } else {
        currentPlayedTime[action.payload.chapterId] = Math.round(action.payload.value);
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          playedTime: currentPlayedTime,
        },
      };
    case ActionType.RESET_USER_SETTINGS:
      return {
        ...state,
        settings: initialState.settings,
      };
    default:
      return state;
  }
};

export default userSettingReducer;

export function setUserSettings(key: string, value: any) {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_USER_SETTINGS,
      payload: {
        key: key,
        value: value,
      },
    });
  };
};

export function setUserPlayedTime(chapterId: string, playedTime: number | null) {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_USER_PLAYED_TIME,
      payload: {
        chapterId: chapterId,
        value: playedTime,
      },
    });
  };
};