import React, { FC, ReactNode, useEffect } from "react";
import iconPlaying from "images/icon-playing.gif";
import PostTypeFeaturedIcon from "_components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import { useMusicPlayer } from "hooks/useMusicPlayer";
import Image from "_components/Image";

export interface ButtonPlayMusicPlayerProps {
  className?: string;
  chapterId?: string;
  renderChildren?: (playing: boolean) => ReactNode;
  renderDefaultBtn?: () => ReactNode;
  renderPlayingBtn?: () => ReactNode;
}

// ** Tat ca cac component nao goi truc tiep ButtonPlayMusicPlayer thi can phai co use client **

const ButtonPlayMusicPlayer: FC<ButtonPlayMusicPlayerProps> = ({
                                                                 className = "",
                                                                 chapterId = "",
                                                                 renderChildren,
                                                                 renderDefaultBtn,
                                                                 renderPlayingBtn,
                                                               }) => {
  const {
    setPlaying,
    currentChapterId,
    setCurrentChapterId,
    playing,
  } = useMusicPlayer();

  // STATE
  const handleClickNewAudio = () => {
    setCurrentChapterId(chapterId);
    setPlaying(true);
  };

  const handleClickButton = () => {
    if (
      !currentChapterId ||
      currentChapterId !== chapterId
    ) {
      return handleClickNewAudio();
    }

    setPlaying(!playing);
  };

  const _renderDefaultBtn = () => {
    if (renderDefaultBtn) {
      return renderDefaultBtn();
    }
    return (
      <PostTypeFeaturedIcon
        className="z-20 hover:scale-105 transform cursor-pointer transition-transform"
        postType="audio"
      />
    );
  };

  const _renderPlayingBtn = () => {
    // RENDER DEFAULT IF IT NOT CURRENT
    if (currentChapterId !== chapterId) {
      return _renderDefaultBtn();
    }

    // RENDER WHEN IS CURRENT
    if (renderPlayingBtn) {
      return renderPlayingBtn();
    }

    return (
      <span
        className="z-10 bg-neutral-900 bg-opacity-60 rounded-full flex  items-center justify-center text-xl text-white border border-white w-11 h-11 cursor-pointer">
        <Image className="w-5" src={iconPlaying} alt="paused" />
      </span>
    );
  };

  return (
    <div
      className={`nc-ButtonPlayMusicPlayer select-none ${className}`}
      onClick={handleClickButton}
    >
      {renderChildren ? (
        renderChildren(playing)
      ) : (
        <>{playing ? _renderPlayingBtn() : _renderDefaultBtn()}</>
      )}
    </div>
  );
};

export default ButtonPlayMusicPlayer;
