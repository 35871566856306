import React, { FC, useEffect } from "react";
interface FBCommentProps {
  url?: string;
}


const FbComment: FC<FBCommentProps> = ({ url = "" }) => {
  useEffect(() => {
    const updateFbCommentUrl = () => {
      const currentUrl = url || window.location.href;
      const fbComments = document.getElementsByClassName("fb-comments");
      if (fbComments.length > 0) {
        fbComments[0].setAttribute("data-href", currentUrl);
        // @ts-ignore
        if (window.FB) {
          // @ts-ignore
          window.FB.XFBML.parse(); // Tải lại plugin Facebook Comments
        }
      }
    };

    // Gọi hàm cập nhật khi component được render
    updateFbCommentUrl();
  }, []); // Chỉ gọi một lần khi component được render

  return (
    <div className="fb-comments" data-numposts="5" data-width="600"></div>
  );
};

export default FbComment;
