import { Dispatch } from "react";
import { makeGetApi } from "../../utils/api";
import { ActionType, Action } from "./actiontTypes";
import { NavItemType } from "../../_components/Navigation/NavigationItem";
import { Route } from "../../routers/types";

// ----------------------------------------------------------------------
interface MenuState {
  error: string | null;
  header: Array<NavItemType> | null;
  footer: Array<NavItemType> | null;
}

const initialState = {
  error: null,
  footer: [],
  header: [],

};

const menuReducer = (state: MenuState = initialState, action: Action): MenuState => {
  switch (action.type) {
    case ActionType.GET_MENUS:
      return {
        ...state,
        header: buildMenus(action.payload.headers),
        footer: buildMenus(action.payload.footers),
      };
    case ActionType.GET_MENU_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default menuReducer;

const buildMenus = (menus: Array<any>) => {
  let childItems: NavItemType[] = [];
  menus.forEach((item: any) => {
    let menu: NavItemType = {
      id: item.id,
      href: item.url as Route,
      name: item.title,
    };
    if (item.children?.length > 0) {
      menu.type = "dropdown";
      menu.children = buildMenus(item.children);
    }
    childItems.push(menu);
  });
  return childItems;
};
export const getMenus = () => {
  return async (dispatch: Dispatch<Action>) => {
    const response = await makeGetApi(`/api/v1/menus`, {});
    try {
      dispatch({
        type: ActionType.GET_MENUS,
        payload: response.data?.data,
      });

    } catch (err) {
      dispatch({
        type: ActionType.GET_MENU_FAIL,
        payload: response.data?.message,
      });
    }
  };
};